function separateValueToStrings(number: number): [string, string] {
  const integerPart = Math.floor(number);
  const decimalPart = Math.round((number - integerPart) * 100);

  let stringInteger = String(integerPart);
  let stringDecimal = String(decimalPart);
  if (decimalPart < 10) stringDecimal = "0" + stringDecimal;
  return [stringInteger, stringDecimal];
}

export default separateValueToStrings;
