import { FlexColumn, FlexFill } from "components/basic/flex";
import Icon from "components/basic/icon";
import { SideMenuLink } from "layout/app/side-menu/side-menu-link";
import { Dispatch, ReactNode, SetStateAction } from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import SideMenuGroup from "./side-menu-group";
import Button from "components/basic/button";
import {
  IconBriefcase,
  IconCards,
  IconDashboard,
  IconEdit,
  IconFileSpreadsheet,
  IconGrid3x3,
  IconHandClick,
  IconHome,
  IconLoader,
  IconPalette,
  IconSettings,
  IconSquare,
  IconStairs,
  IconTypography,
} from "@tabler/icons-react";

export type LinkItens = {
  label: string;
  linkTo: string;
  icon?: ReactNode;
};

export type SideMenuProps = {
  menuVisibility: boolean;
  setMenuVisibility: Dispatch<SetStateAction<boolean>>;
  headerHeight: number;
  linkItens?: Record<string, LinkItens>;
};
const SideMenu: Component<SideMenuProps> = ({
  menuVisibility,
  setMenuVisibility,
  headerHeight,
}) => {
  return (
    <FlexColumn
      {...(!menuVisibility && { onClick: () => setMenuVisibility(true) })}
      sx={{
        width: "250px",
        background: "white",
        px: 2,
        height: `calc(100vh - ${headerHeight}px)`,
        position: "sticky",
        top: `${headerHeight}px`,
        zIndex: 1,
        boxShadow: 5,
        transition: "all 0.2s",
        overflow: "clip",
        overflowY: "auto",
        flexShrink: 0,
        ...(!menuVisibility && {
          width: "40px",
          cursor: "pointer",
          "&:hover": { width: "45px", mr: "-5px" },
          overflowY: "hidden",
          p: 0,
        }),
      }}
    >
      {menuVisibility && (
        <FlexColumn sx={{ gap: "0.8rem", py: 1 }}>
          <SideMenuGroup label="Documentation" linkTo="/app/documentation">
            <SideMenuLink
              label="Palette"
              linkTo="/app/documentation#palette"
              icon={<IconPalette stroke={1.5} />}
            />
            <SideMenuLink
              label="Typography"
              linkTo="/app/documentation#typography"
              icon={<IconTypography stroke={1.5} />}
            />
            <SideMenuLink
              label="Buttons"
              linkTo="/app/documentation#buttons"
              icon={<IconHandClick stroke={1.5} />}
            />
            <SideMenuLink
              label="Forms"
              linkTo="/app/documentation#forms"
              icon={<IconFileSpreadsheet stroke={1.5} />}
            />
            <SideMenuLink
              label="Cards"
              linkTo="/app/documentation#cards"
              icon={<IconCards stroke={1.5} />}
            />
            <SideMenuLink
              label="DataGrid"
              linkTo="/app/documentation#data-grid"
              icon={<IconGrid3x3 stroke={1.5} />}
            />
            <SideMenuLink
              label="Steps Controller"
              linkTo="/app/documentation#steps-controller"
              icon={<IconStairs stroke={1.5} />}
            />
          </SideMenuGroup>

          <SideMenuGroup label="Authenticated">
            <SideMenuLink
              label="Dashboard"
              linkTo="/app/dashboard"
              icon={<IconDashboard stroke={1.5} />}
            />

            <SideMenuLink
              label="Real States"
              linkTo="/app/real-states"
              icon={<IconBriefcase stroke={1.5} />}
            />
            <SideMenuLink
              label="Properties"
              linkTo="/app/real-state/properties"
              icon={<IconHome stroke={1.5} />}
            />
            <SideMenuLink
              label="Settings"
              linkTo="/app/real-state/settings"
              icon={<IconSettings stroke={1.5} />}
            />
            <SideMenuLink
              label="CRUD"
              linkTo="/app/real-state/properties"
              icon={<IconEdit stroke={1.5} />}
            />
          </SideMenuGroup>

          <SideMenuGroup label="Unauthenticated">
            <SideMenuLink
              label="Landing"
              linkTo="/app/"
              icon={<IconHome stroke={1.5} />}
            />
            <SideMenuLink
              label="Register"
              linkTo="/app/register"
              icon={<IconEdit stroke={1.5} />}
            />
          </SideMenuGroup>

          <SideMenuGroup label="Side Menu Example">
            <SideMenuLink label="Subpage 1" linkTo="" />
            <SideMenuLink label="Subpage 2" linkTo="" />
            <SideMenuLink label="Subpage 3" linkTo="" />
          </SideMenuGroup>

          <SideMenuGroup label="Outros">
            <SideMenuLink
              label="Loading"
              linkTo="/app/loading"
              icon={<IconLoader stroke={1.5} />}
            />
            <SideMenuLink
              label="Tests"
              linkTo="/app/test"
              icon={<IconLoader stroke={1.5} />}
            />
            <SideMenuLink
              label="Zustand Test"
              linkTo="/app/test-zustand"
              icon={<IconLoader stroke={1.5} />}
            />
          </SideMenuGroup>
        </FlexColumn>
      )}

      <FlexFill />

      <Button
        variant="ghost-text"
        sx={{
          width: "100%",
          justifyContent: "flex-end",
          p: 0,
        }}
        onClick={() => setMenuVisibility((previous) => !previous)}
      >
        {menuVisibility && "Encolher"}
        <Icon
          as={menuVisibility ? BiChevronLeft : BiChevronRight}
          sx={{ width: "40px", height: "40px", p: 1 }}
        />
      </Button>
    </FlexColumn>
  );
};

export default SideMenu;

export const SideMenuApp: Component<SideMenuProps> = ({
  menuVisibility,
  setMenuVisibility,
  headerHeight,
  linkItens,
}) => {
  return (
    <FlexColumn
      {...(!menuVisibility && { onClick: () => setMenuVisibility(true) })}
      sx={{
        width: "250px",
        background: "white",
        px: 2,
        height: `calc(100vh - ${headerHeight}px)`,
        position: "sticky",
        top: `${headerHeight}px`,
        zIndex: 1,
        boxShadow: 5,
        transition: "all 0.2s",
        overflow: "clip",
        overflowY: "auto",
        flexShrink: 0,
        ...(!menuVisibility && {
          width: "40px",
          cursor: "pointer",
          "&:hover": { width: "45px", mr: "-5px" },
          overflowY: "hidden",
          p: 0,
        }),
      }}
    >
      {menuVisibility && (
        <FlexColumn sx={{ py: 1 }}>
          {linkItens &&
            Object.entries(linkItens).map(
              ([key, { label, linkTo, icon }]: [string, LinkItens]) => (
                <SideMenuLink label={label} linkTo={linkTo} icon={icon} />
              )
            )}
        </FlexColumn>
      )}

      <FlexFill />

      <Button
        variant="ghost-text"
        sx={{
          width: "100%",
          justifyContent: "flex-end",
          p: 0,
        }}
        onClick={() => setMenuVisibility((previous) => !previous)}
      >
        {menuVisibility && "Encolher"}
        <Icon
          as={menuVisibility ? BiChevronLeft : BiChevronRight}
          sx={{ width: "40px", height: "40px", p: 1 }}
        />
      </Button>
    </FlexColumn>
  );
};
