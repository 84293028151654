import Form from "components/form/form-store";
import Button from "components/basic/button";
import { FlexColumn, FlexRow } from "components/basic/flex";
import Base from "components/basic/base";
import Text from "components/basic/text";
import { BiCheck } from "react-icons/bi";
import Separator from "components/basic/separator";
import Card from "components/basic/card/card";

import Image from "components/basic/image";
import { cssKeyframes } from "styles/animations";
import CustomRadio from "components/form/primitives/custom-radio";
import PriceCard2 from "../../../unauthenticated/landing/pricing/price-card-2";
import { PriceCards } from "../../../unauthenticated/landing/pricing/price-cards";
import { useStepsContext } from "components/steps-controller/steps-context";
import PriceCard from "pages/unauthenticated/landing/pricing/price-card";
import {
  IconBuilding,
  IconBuildingCommunity,
  IconHome,
  IconStar,
} from "@tabler/icons-react";
import FormSubmitBehavior from "components/form/form-submit";

const attributes = ["Aluguel e venda", "Até 100 imóveis", "Até 3 usuários"];
const discounts = {
  monthly: 0,
  quarterly: 5,
  annual: 15,
};
const paymentPeriod = "monthly";
type PlansProps = {
  previousStepId: string;
  nextStepId: string;
};
const RegisterPlans: React.FC<PlansProps> = ({
  previousStepId,
  nextStepId,
}) => {
  const stepsContext = useStepsContext();
  const { setCurrentStepId } = stepsContext;
  return (
    <FlexColumn sx={{ gap: 2, width: "100%" }}>
      <FlexRow
        sx={{
          justifyContent: "space-evenly",
          gap: 5,
          alignItems: "center",
          width: "100%",
        }}
      >
        <CustomRadio name="plan" value="basic">
          {({ selected }) => (
            <PriceCard2
              href={`http://localhost:5000/oauth2/login?period=${paymentPeriod}&plan=basic`}
              icon={IconHome}
              title="BÁSICO"
              price={115}
              discount={discounts[paymentPeriod]}
              features={[
                { label: "Usuários", quantity: 2 },
                { label: "Imóveis", quantity: 150 },
                { label: "Contratos", quantity: "infinite" },
                { label: "Gestão de locação", check: true },
                { label: "Gestão de venda", check: true },
                { label: "Preço por usuário adicional", value: 80 },
                { label: "Preço por imóvel adicional", value: 1.5 },
              ]}
              backgroundVariant={1}
              selected={selected}
            />
          )}
        </CustomRadio>
        <CustomRadio name="plan" value="explorer">
          {({ selected }) => (
            <PriceCard2
              href={`http://localhost:5000/oauth2/login?period=${paymentPeriod}&plan=advanced`}
              icon={IconBuilding}
              title="DESBRAVADOR"
              price={235}
              discount={discounts[paymentPeriod]}
              features={[
                { label: "Usuários", quantity: 5 },
                { label: "Imóveis", quantity: 500 },
                { label: "Contratos", quantity: "infinite" },
                { label: "Gestão de locação", check: true },
                { label: "Gestão de venda", check: true },
                { label: "Preço por usuário adicional", value: 40 },
                { label: "Preço por imóvel adicional", value: 0.4 },
              ]}
              backgroundVariant={2}
              selected={selected}
            />
          )}
        </CustomRadio>
        <CustomRadio name="plan" value="professional">
          {({ selected }) => (
            <PriceCard2
              href={`http://localhost:5000/oauth2/login?period=${paymentPeriod}&plan=pro`}
              icon={IconBuildingCommunity}
              title="PROFISSIONAL"
              price={350}
              discount={discounts[paymentPeriod]}
              features={[
                { label: "Usuários", quantity: 10 },
                { label: "Imóveis", quantity: 2000 },
                { label: "Contratos", quantity: "infinite" },
                { label: "Gestão de locação", check: true },
                { label: "Gestão de venda", check: true },
                { label: "Preço por usuário adicional", value: 30 },
                { label: "Preço por imóvel adicional", value: 0.15 },
              ]}
              backgroundVariant={3}
              selected={selected}
            />
          )}
        </CustomRadio>
        <CustomRadio name="plan" value="premium">
          {({ selected }) => (
            <PriceCard2
              href={`http://localhost:5000/oauth2/login?period=${paymentPeriod}&plan=premium`}
              icon={IconStar}
              title="PREMIUM"
              price={470}
              discount={discounts[paymentPeriod]}
              features={[
                { label: "Usuários", quantity: 20 },
                { label: "Imóveis", quantity: 5000 },
                { label: "Contratos", quantity: "infinite" },
                { label: "Gestão de locação", check: true },
                { label: "Gestão de venda", check: true },
                { label: "Preço por usuário adicional", value: 20 },
                { label: "Preço por imóvel adicional", value: 0.08 },
              ]}
              backgroundVariant={4}
              selected={selected}
            />
          )}
        </CustomRadio>
      </FlexRow>
      <FlexRow sx={{ justifyContent: "center", gap: 2, width: "100%" }}>
        {/* <Button onClick={() => setCurrentStepId(previousStepId)} sx={{width: "50%"}}>Voltar</Button> */}
        <FormSubmitBehavior onSubmit={() => setCurrentStepId(nextStepId)}>
          <Button sx={{ width: "100%" }}>Avançar</Button>
        </FormSubmitBehavior>
      </FlexRow>
    </FlexColumn>
  );
};

type PlanCardProps = {
  title: string;
  price: number;
  attributes: string[];
  iconSrc: string;
  selected: boolean;
};
const PlanCard: ComponentWithChildren<PlanCardProps> = ({
  title,
  price,
  attributes,
  iconSrc,
  selected,
}) => {
  return (
    <Card
      size="unset"
      addOutline
      sx={{
        flexDirection: "column",
        alignItems: "flex-start",
        cursor: "pointer",
        boxShadow: 10,
        borderColor: "primary.main",
        flexGrow: 1,
        borderRadius: 2,
        transition: "all 0.2s",
        ...(selected && { animation: `${cssKeyframes.pop} 0.3s backwards` }),
        "&:hover": { transform: "scale(1.02)" },
      }}
    >
      <FlexColumn
        sx={{
          background: selected ? "primary.main" : "neutral.n3",
          width: "100%",
          p: 1,
          pb: 3,
          gap: 1,
        }}
      >
        <Text
          sx={{ color: "primary.contrastText", textTransform: "uppercase" }}
        >
          {title}
        </Text>
        {/* <Icon color={"white"} sx={{ maxHeight: "50px", alignSelf: "center" }} /> */}
        <Image src={iconSrc} sx={{ alignSelf: "center", width: "70px" }} />
      </FlexColumn>
      <Separator direction="col" shading="dark" sx={{ mt: "-1px" }} />
      <FlexColumn sx={{ width: "100%", p: 1, gap: 1, color: "text.main" }}>
        <Text
          sx={{
            color: "inherit",
            alignSelf: "center",
            fontWeight: "bold",
          }}
        >
          <Text sx={{ fontSize: 2 }}>R$</Text>
          <Text sx={{ fontSize: 5 }}>{Math.floor(price)}</Text>
          <Text sx={{ fontSize: 0 }}>
            {price.toFixed(2).replaceAll(/\d+\./g, "")}
          </Text>
        </Text>
        <Separator direction="col" shading="dark" />
        <Text sx={{ color: "inherit", alignSelf: "center", fontSize: 0 }}>
          Gestão Completa
        </Text>
        <Separator direction="col" shading="dark" />
        {attributes.map((attribute) => {
          return (
            <Text key={attribute} sx={{ color: "inherit", fontSize: 0 }}>
              <Base as={BiCheck} sx={{ color: "primary.main" }} />
              {attribute}
            </Text>
          );
        })}
      </FlexColumn>
    </Card>
  );
};

export default RegisterPlans;
