import { ThemeUIProvider } from "theme-ui";
import { lightTheme } from "themes/light/theme";
import { Suspense, lazy, useEffect, useState } from "react";
import AppLayout, { AgencyLayout, ApplicationLayout } from "layout/app";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ManageAgencies from "pages/authenticated/manage-agencies/manage-agencies";
import ConfigAgencies from "pages/authenticated/config-real-state";
import ManageProperties from "pages/authenticated/manage-properties";
import { MantineProvider } from "@mantine/core";
import RealStateSettings from "pages/authenticated/agency-pages/real-state-settings";
import { LoadingPage } from "pages/other/loading-page";
import TestPage from "pages/other/test-page";
import CreateRealState from "pages/authenticated/create-real-state";

import NotFoundPage from "pages/unauthenticated/not-found";
import AuthenticationCallback from "pages/authenticated/callback";
import AuthenticationProvider from "context/authentication";
import { useAuth } from "react-oidc-context";
import EarlyAdopterPage from "pages/authenticated/early-adopter";
import ServiceTermsVersion1 from "pages/unauthenticated/service_terms/Version1";
import { getAgencyById } from "services/housien/agencies";
import { agencyContext } from "pages/authenticated/manage-agencies/agencies-context";
import OverviewPage from "pages/authenticated/agency-pages/overview";
import StepsProvider from "components/steps-controller/steps-context";
import { content } from "pages/authenticated/register-agency/header";

const ZustandTest = lazy(() => import("pages/other/zustand-test"));
const DashboardPage = lazy(() => import("pages/authenticated/agency-pages/dashboard"));
const Register = lazy(() => import("pages/authenticated/register-agency"));
const ComponentsPage = lazy(
  () => import("pages/documentation/documentation-page")
);
const Landing = lazy(() => import("pages/unauthenticated/landing"));
const Auth = lazy(() => import("pages/unauthenticated/auth/auth"));

function App() {
  return (
    <MantineProvider>
      <ThemeUIProvider theme={lightTheme}>
        <AuthenticationProvider>
          <BrowserRouter>
            <ToastContainer position="top-right" autoClose={5000} />
            <Suspense fallback={<LoadingPage />}>
              <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="/auth" element={<Auth />} />
                <Route path="app/*" element={<LoggedRoutes />} />
                <Route
                  path="application/agencies/:agency_id/*"
                  element={<LoggedAgencyRoutes />}
                />
                <Route path="application/*" element={<LoggedAppRoutes />} />

                <Route path="/earlyadopter" element={<EarlyAdopterPage />} />
                <Route
                  path="/serviceterms/*"
                  element={<ServiceTermsRoutes />}
                />
                <Route
                  path="/authentication/callback"
                  element={<AuthenticationCallback />}
                />
                <Route path="*" element={<Navigate to="agencies" />} />
              </Routes>
            </Suspense>
          </BrowserRouter>
        </AuthenticationProvider>
      </ThemeUIProvider>
    </MantineProvider>
  );
}

export const LoggedRoutes = () => {
  const auth = useAuth();

  // if (!auth.isAuthenticated) return <Navigate to="/" />;

  return (
    <AppLayout>
      <Suspense fallback={<LoadingPage />}>
        <Routes>
          <Route path="dashboard" element={<DashboardPage />} />
          <Route path="documentation/*" element={<ComponentsPage />} />
          <Route path="real-states" element={<ManageAgencies />} />
          <Route path="real-states/create" element={<CreateRealState />} />
          <Route path="real-state" element={<ConfigAgencies />} />
          <Route path="real-state/properties" element={<ManageProperties />} />
          <Route path="real-state/settings" element={<RealStateSettings />} />
          <Route path="register" element={<Register />} />
          <Route path="zustand" element={<ZustandTest />} />
          <Route path="crud" element={<ManageProperties />} />
          <Route path="loading" element={<LoadingPage />} />
          <Route path="test" element={<TestPage />} />
          <Route path="test-zustand" element={<ZustandTest />} />
          <Route
            path="*"
            element={<NotFoundPage redirect="/app/dashboard" />}
          />
        </Routes>
      </Suspense>
    </AppLayout>
  );
};

export const LoggedAppRoutes = () => {
  return (
    <ApplicationLayout>
      <Suspense fallback={<LoadingPage />}>
        <Routes>
          <Route path="agencies" element={<ManageAgencies />} />
          <Route path="register" element={<Register />} />
          <Route path="*" element={<Navigate to="agencies" />} />
        </Routes>
      </Suspense>
    </ApplicationLayout>
  );
};

export type RouteParams = {
  agency_id: string;
};
export const LoggedAgencyRoutes = () => {
  const { agency_id } = useParams<RouteParams>();
  const auth = useAuth();
  const [agency, setAgency] = useState<Record<string, any> | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (agency_id) {
      getAgencyById(auth.user?.access_token, agency_id)
        .then((agency) => {
          console.log(agency);
          setAgency(agency);
        })
        .catch((error) => {
          console.error("Error fetching agency:", error);
        })
        .finally(() => setIsLoading(false));
    }
  }, [auth.user?.access_token, agency_id]);

  if (isLoading) {
    return <LoadingPage />;
  }

  const { Provider: AgencyContextProvider } = agencyContext;

  if (agency) {
    return (
      <AgencyContextProvider initialState={agency}>
        <AgencyLayout>
          <Suspense fallback={<LoadingPage />}>
            <Routes>
              <Route path="" element={<OverviewPage />} />
              <Route path="dashboard" element={<DashboardPage />} />
              {/* <Route path="/" element={<ConfigAgencies />} /> */}
              <Route path="properties" element={<ManageProperties />} />
              <Route path="settings" element={<RealStateSettings />} />
              <Route path="crud" element={<ManageProperties />} />
              <Route path="*" element={<Navigate to="dashboard" />} />
            </Routes>
          </Suspense>
        </AgencyLayout>
      </AgencyContextProvider>
    );
  }

  return <NotFoundPage redirect="/application/agencies" />;
};

export const ServiceTermsRoutes = () => {
  const versions = [<ServiceTermsVersion1 />];
  return (
    <Routes>
      {versions.map((version, index) => (
        <Route key={index + 1} path={`v${index + 1}`} element={version} />
      ))}
      <Route path={"*"} element={versions[versions.length - 1]} />
    </Routes>
  );
};

export default App;
