import {
  IconCalendar,
  IconDashboard,
  IconHome,
  IconReport,
  IconSettings,
  IconUser,
} from "@tabler/icons-react";
import Button from "components/basic/button";
import Card, { CardWithSections } from "components/basic/card/card";
import CardHeader from "components/basic/card/card-header";
import { CardSection } from "components/basic/card/card-section";
import { FlexColumn, FlexRow } from "components/basic/flex";
import Text from "components/basic/text";
import Tooltip from "components/basic/tooltip";
import BreadCrumb from "components/composed/breadcrumb";
import TextField from "components/form/custom-fields/text-field";
import FormProvider from "components/form/form-store";
import InputAdornment from "components/form/primitives/input-adornment";
import { BiFilter, BiMessageAltX, BiPlus, BiSearch } from "react-icons/bi";
import { useNavigate } from "react-router";
import { agenciesGetRequest, agenciesPostRequest } from "../services/requests";
import { useAuth, withAuthenticationRequired } from "react-oidc-context";
import { useCrudStore } from "../manage-properties/crud-store";
import { useDataGridStore } from "components/basic/data-grid/data-grid-store";
import { useEffect, useState } from "react";
import Link from "components/basic/link";

const ManageAgencies = () => {
  return (
    <>
      <FlexColumn>
        <Text variant="h3">Agencies</Text>
        <BreadCrumb />
      </FlexColumn>
      <SearchBar />
      <RealStates />
    </>
  );
};

export const RealStates = () => {
  const auth = useAuth();

  const [agencies, setAgencies] = useState<Array<Record<string, any>>>([]);

  useEffect(() => {
    agenciesGetRequest(auth.user?.access_token)
      .then((response) => {
        setAgencies(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [auth.user?.access_token]);

  return (
    <FlexRow sx={{ gap: 2, flexWrap: "wrap" }}>
      {agencies.length != 0 ? (
        agencies.map((agency) => <RealStateCard agency={agency} />)
      ) : (
        <NotFoundAgenciesCard />
      )}
    </FlexRow>
  );
};

type RealStateCardProps = {
  agency: { [key: string]: any }; //TODO Colocar tipo definitivo dos dados da agência
};
export const RealStateCard: Component<RealStateCardProps> = ({ agency }) => {
  const navigate = useNavigate();
  return (
    <CardWithSections
      size="micro"
      onClick={() => navigate(agency?.id.toString())}
      sx={{
        flexGrow: 1,
        cursor: "pointer",
        transition: "transform 0.2s",
        mb: "2px",
        border: "1px solid transparent",
        "&:hover": {
          background: "neutral.n1",
          transform: "scale(1.02)",
          borderColor: "primary.main",
        },
      }}
    >
      <CardHeader>
        <FlexRow sx={{ gap: 1, p: 1 }}>
          <AgencyIcon agency={agency} />
          <FlexColumn sx={{ height: "100%", justifyContent: "center" }}>
            <Text sx={{ fontWeight: 600, whiteSpace: "nowrap" }}>
              {agency.name}
            </Text>
            <Text sx={{ color: "text.faded" }}>{agency.status}</Text>
          </FlexColumn>
        </FlexRow>
      </CardHeader>

      <CardSection sx={{ justifyContent: "center" }}>
        <RealStateCardButton href={getLink("dashboard")} tooltip="Dashboard">
          <IconDashboard />
        </RealStateCardButton>
        <RealStateCardButton href={getLink("properties")} tooltip="Properties">
          <IconHome />
        </RealStateCardButton>
        <RealStateCardButton href={getLink("customers")} tooltip="Customers">
          <IconUser />
        </RealStateCardButton>
        <RealStateCardButton href={getLink("calendar")} tooltip="Calendar">
          <IconCalendar />
        </RealStateCardButton>
        <RealStateCardButton href={getLink("reports")} tooltip="Reports">
          <IconReport />
        </RealStateCardButton>
        <RealStateCardButton href={getLink("settings")} tooltip="Settings">
          <IconSettings />
        </RealStateCardButton>
      </CardSection>
    </CardWithSections>
  );

  function getLink(page: string) {
    return `${agency.id}/${page}`;
  }
};

type RealStateCardButtonProps = { tooltip?: string; href?: string };
export const RealStateCardButton: ComponentWithChildren<
  RealStateCardButtonProps
> = ({ tooltip, href, children }) => {
  return (
    <Tooltip label={tooltip ?? ""}>
      <Link
        variant="ghost"
        size="auto"
        sx={{
          fontSize: 5,
          flexGrow: 0,
          color: "text.faded",
          p: 1,
          "&:hover": { color: "text.main" },
        }}
        href={href}
      >
        {children}
      </Link>
    </Tooltip>
  );
};

type SearchBarProps = {};
export const SearchBar: Component<SearchBarProps> = ({}) => {
  const navigate = useNavigate();
  const auth = useAuth();
  return (
    <Card size="small" sx={{ width: "100%", gap: 1, flexWrap: "wrap" }}>
      <FormProvider>
        <Button
          size="small"
          sx={{ flexGrow: 1 }}
          onClick={() => navigate("/application/register")}
        >
          <BiPlus />
          New Real State
        </Button>
        <Button size="small" variant="outlined" sx={{ flexGrow: 1 }}>
          <BiFilter />
          Filters
        </Button>
        <TextField
          name="search"
          leftAdornment={
            <InputAdornment
              variant="button"
              buttonVariant="outlined"
              sx={{ color: "text.main" }}
            >
              <BiSearch />
            </InputAdornment>
          }
          sx={{ flexGrow: 999, flexShrink: 1, border: "none" }}
        />
      </FormProvider>
    </Card>
  );
};

export const NotFoundAgenciesCard: Component = () => {
  return (
    <Card
      variant="outlined"
      sx={{
        width: "100%",
        background: "neutral.n2",
        justifyContent: "center",
      }}
    >
      <Text
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
        }}
      >
        <BiMessageAltX size={30} />
        Could not find any real states
      </Text>
    </Card>
  );
};

export const AgencyIcon: Component = ({ agency, fontSize = 50 }) => {
  return (
    <Card
      size="unset"
      sx={{
        background: "primary.main",
        width: fontSize,
        height: fontSize,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Text
        sx={{
          fontSize: `${fontSize / 2}px`,
          fontWeight: "900",
          color: "white",
        }}
      >
        {agency.name[0].toUpperCase()}
      </Text>
    </Card>
  );
};

export default withAuthenticationRequired(ManageAgencies);
