import { MutableRefObject, useEffect, useState } from "react";
import { useFormStoreFieldValue, useFormStoreSetters } from "../form-store";
import Base from "components/basic/base";

export type CustomRadioFnParams = {
  selected: boolean;
};

export type CustomRadioProps = {
  name: string;
  value: any;
  checkboxRef?: MutableRefObject<HTMLElement | undefined>;
};
const CustomRadio: ComponentWithChildrenFn<
  CustomRadioFnParams,
  CustomRadioProps
> = ({ name, value, checkboxRef, children }) => {
  const selectedValue = useFormStoreFieldValue(name);
  const isSelected = selectedValue === value;
  const { setFieldValue } = useFormStoreSetters();

  return (
    <Base
      ref={checkboxRef}
      onClick={(event) => {
        event.preventDefault();
        setFieldValue(name, value);
      }}
      sx={{ display: "contents", cursor: "pointer" }}
    >
      {typeof children === "function" && children({ selected: isSelected })}
      {typeof children !== "function" && <>{children}</>}
    </Base>
  );
};

export default CustomRadio;
